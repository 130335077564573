<template>
    <div class="home">
        <div style="height: 10rem;width: 100vw;display: flex;">
            <div style="width: 20rem;text-align: left;margin-top: 2rem;margin-left: 1rem;color: white;">
                <span
                    style="display: inline-block;width: 250px;font-size: 0.9rem;font-weight: 600;">幼儿园入(出)园接送信息登记</span>
                <span
                    style="display: inline-block;width: 250px;font-size: 12px;transform: scale(0.7);margin-left: -2.4rem;">
                    Kindergarten in (out) transfer information registration</span>
            </div>
            <img src="../assets/img/person.png" width="350rem" height="180rem"
                style="background-size: cover;margin-top:2.5rem;margin-left: -10rem;position: relative;z-index: -1;left: 1rem;" />
        </div>

        <div class="contain" style="">
            <div
                style="width: 80%;background-color: white;border-radius: 10px;padding-bottom: 3rem;padding-top: 2rem;padding-left: 3rem;">
                <template v-if="status==1">
                    <div style="font-weight: bold;padding-left: 1rem;">幼儿园入(出)园接送信息登记</div>
                    <div style="margin-top: 0.5rem;margin-right: 0.5rem;position: relative;right: 1rem;">
                        <el-descriptions class="margin-top" :column="1" size="mini" border>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span style="display: inline-block;width: 4.5rem;">请选择园区：</span>
                                </template>
                                <el-select v-model="other.nursery_id" style="margin-top: 0.5rem;" filterable
                                 transfer="true" :popper-append-to-body="false"
                                    @change="changSelect" placeholder="可以进行文字搜索" size="mini">
                                    <el-option v-for="item in options" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-descriptions-item>
                        </el-descriptions>
                        <!-- 孩子信息 -->
                        <div style="margin-top: 1rem;" v-for="(item,index) in childNum">
                            <span
                                style="font-size: 0.8rem;padding-bottom: 0.5rem;display: inline-block;font-weight: bold;">孩子信息:</span>
                            <el-descriptions class="margin-top" :column="1" size="mini" border>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span style="display: inline-block;width: 4.5rem;">孩子姓名：</span>
                                    </template>
                                    <el-input placeholder="请输入" v-model="childName[index]" size="mini"></el-input>
                                </el-descriptions-item>
                            </el-descriptions>
                            <el-descriptions class="margin-top" style="margin-top: 0.5rem;" :column="1" size="mini"
                                border>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span style="display: inline-block;width: 4.5rem;">孩子班级：</span>
                                    </template>
                                    <el-input placeholder="请输入" v-model="childClass[index]" size="mini"></el-input>
                                </el-descriptions-item>
                            </el-descriptions>
                        </div>
                        <div style="width: 100%;display: flex;justify-content: center;margin-top: 0.5rem;">
                            <i class="el-icon-circle-plus-outline" style="color: rgb(20, 224, 224);font-size: 1.5rem;"
                                @click="addChild"></i>
                        </div>
                    </div>
                    <div style="margin-top: 0.5rem;margin-right: 0.5rem;position: relative;right: 1rem;">
                        <span
                            style="font-size: 0.8rem;padding-bottom: 0.5rem;display: inline-block;font-weight: bold;">家长信息:</span>
                        <el-descriptions :column="1" size="mini" border>
                            <el-descriptions-item style="margin-top: 0.5rem;">
                                <template slot="label">
                                    <span style="display: inline-block;width: 4.5rem;">家长姓名：</span>
                                </template>
                                <el-input placeholder="请输入" v-model="other.parent_name" size="mini"></el-input>
                            </el-descriptions-item>
                        </el-descriptions>
                        <el-descriptions :column="1" size="mini" border style="margin-top: 0.5rem;">
                            <el-descriptions-item>
                                <template slot="label">
                                    <span style="display: inline-block;width: 4.5rem;">家长手机：</span>
                                </template>
                                <el-input placeholder="请输入" v-model="other.parent_phone" maxlength="11" size="mini">
                                </el-input>
                            </el-descriptions-item>
                        </el-descriptions>
                        <el-descriptions :column="1" size="mini" border style="margin-top: 0.5rem;">
                            <el-descriptions-item>
                                <template slot="label">
                                    <span style="display: inline-block;width: 4.5rem;">申请类型：</span>
                                </template>
                                <el-radio-group v-model="other.type" style="display: flex;">
                                    <el-radio :label="2">上学</el-radio>
                                    <el-radio :label="1">放学</el-radio>
                                </el-radio-group>
                            </el-descriptions-item>
                        </el-descriptions>
                        <el-descriptions :column="1" size="mini" border style="margin-top: 0.5rem;">
                            <el-descriptions-item>
                                <template slot="label">
                                    <span style="display: inline-block;width: 4.5rem;">申请理由：</span>
                                </template>
                                <el-input placeholder="请输入" v-model="other.reason" size="mini"></el-input>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                    <el-button type="primary" @click="handleSub" size="mini" round
                        style="width: 8rem;letter-spacing: 10px;margin-left: 3rem;margin-top: 1rem;">提交</el-button>
                </template>
                <!-- 信息审核中 -->
                <template v-if="status==2">
                    <img src="../assets/img/aa.png" style="width: 90%;height: 90%;" />
                    <div style="color: #4481ff;font-weight: bold;">你的信息正在审核中请稍后......</div>
                    <div style="margin-top: 2rem;">
                        <el-button type="primary" round style="width: 10rem;letter-spacing: 10px;margin-left: 2rem;" :disabled="reshBtn"
                            @click="refresh">刷新</el-button>
                    </div>
                </template>
                <!-- 审核失败 -->
                <template v-if="status==3">
                    <img src="../assets/img/bb.png" style="width: 90%;height: 90%;" />
                    <div style="color: #4481ff;font-weight: bold;text-align: center;padding-right: 2rem;">
                        你的信息审核不通过，请重新审核或联系在场的工作人员</div>
                    <div style="margin-top: 2rem;">
                        <el-button type="primary" round
                            style="width: 10rem;letter-spacing: 5px;margin-left: 2rem;padding-right: 1rem;"
                            @click="reSubmit">重新提交</el-button>
                    </div>
                </template>
                <!-- 审核成功 -->
                <template v-if="status==4">
                    <div style="font-weight: bold;padding-left: 1rem;">幼儿园入(出)园接送信息登记</div>
                    <div style="margin-top: 0.5rem;margin-right: 0.5rem;position: relative;right: 1rem;">
                        <el-descriptions class="margin-top" :column="1" size="mini" border>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span style="display: inline-block;width: 4.5rem;">所在园区：</span>
                                </template>
                                {{other.school}}
                            </el-descriptions-item>
                        </el-descriptions>
                        <!-- 孩子信息 -->
                        <div style="margin-top: 1rem;" v-for="(item,index) in childNum">
                            <span
                                style="font-size: 0.8rem;padding-bottom: 0.5rem;display: inline-block;font-weight: bold;">孩子信息:</span>
                            <el-descriptions class="margin-top" :column="1" size="mini" border>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span style="display: inline-block;width: 4.5rem;">孩子姓名：</span>
                                    </template>
                                    {{childName[index]}}
                                </el-descriptions-item>
                            </el-descriptions>
                            <el-descriptions class="margin-top" style="margin-top: 0.5rem;" :column="1" size="mini"
                                border>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span style="display: inline-block;width: 4.5rem;">孩子班级：</span>
                                    </template>
                                    {{childClass[index]}}
                                </el-descriptions-item>
                            </el-descriptions>
                        </div>
                    </div>
                    <div style="margin-top: 1rem;margin-right: 0.5rem;position: relative;right: 1rem;">
                        <span
                            style="font-size: 0.8rem;padding-bottom: 0.5rem;display: inline-block;font-weight: bold;">家长信息:</span>
                        <el-descriptions :column="1" size="mini" border>
                            <el-descriptions-item style="margin-top: 0.5rem;">
                                <template slot="label">
                                    <span style="display: inline-block;width: 4.5rem;">家长姓名：</span>
                                </template>
                                {{other.parent_name}}
                            </el-descriptions-item>
                        </el-descriptions>
                        <el-descriptions :column="1" size="mini" border style="margin-top: 0.5rem;">
                            <el-descriptions-item>
                                <template slot="label">
                                    <span style="display: inline-block;width: 4.5rem;">家长手机：</span>
                                </template>
                                {{other.parent_phone}}
                                </el-input>
                            </el-descriptions-item>
                        </el-descriptions>
                        <el-descriptions :column="1" size="mini" border style="margin-top: 0.5rem;">
                            <el-descriptions-item>
                                <template slot="label">
                                    <span style="display: inline-block;width: 4.5rem;">申请类型：</span>
                                </template>
                                <span v-if="other.type==2">上学</span>
                                <span v-if="other.type==1">放学</span>
                            </el-descriptions-item>
                        </el-descriptions>
                        <el-descriptions :column="1" size="mini" border style="margin-top: 0.5rem;">
                            <el-descriptions-item>
                                <template slot="label">
                                    <span style="display: inline-block;width: 4.5rem;">申请理由：</span>
                                </template>
                                {{other.reason}}
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                    <div style="margin: 2rem -1rem;height: 40px;background: white;justify-content: center;width: 100%;
      display: flex;align-items: center;border-radius: 8px;font-weight: bold;color:#4481ff;">
                        <span style="padding-bottom: 0.2rem;">审核时间：</span>
                        <span style="padding-left: 1rem;">{{time}}</span>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                options: [],
                value: [],
                childName: [],
                childClass: [],
                childNum: 1,
                other: {
                    parent_phone: '',
                    parent_name: '',
                    nursery_id: '',
                    type: '',
                    reason: '',
                    school: ''
                },
                time:'',
                status: 1,
                childId: [],
				reshBtn:false
            }
        },
        mounted() {
            this.getAllNursery()
        },
        methods: {
            async getAllNursery() {
                const res = await this.http.post("show/allnursery")
                this.options = res
            },
            //获取下拉框的label值
            changSelect(val) {
                var that = this
                var school = ''
                this.options.map(e => {
                    if (e.id == val) {
                        school = e.name
                    }
                })
                this.other.school = school
            },
            //动态增加孩子
            addChild() {
                this.childNum++
            },
            //刷新
            async refresh() {
                const res = await this.http.post("/show/getStatus", {
                    idArr: this.childId
                })
                if (res.code == '0') {
                    this.status = 3
                } else if (res.code == '1') {
                    this.status = 4
                    this.time=res.time
                } else if (res.code == '-1') {
					this.$message('您当前的审核状态是：' + res.msg+',请在十秒后手动刷新')
					this.reshBtn=true
					setTimeout(()=>{
				    this.reshBtn=false
					},10000)
                }
            },
            //重新提交
            reSubmit() {
                this.status = 1
                this.other = {}
                this.childName = []
                this.childClass = []
                this.childNum = 1
            },
            //表单提交
            async handleSub() {
                let arr = []
                for (let i = 0; i < this.childName.length; i++) {
                    let main = {
                        child_name: this.childName[i],
                        class_name: this.childClass[i]
                    }
                    arr.push(main)
                }
                var str = this.other.parent_phone
                var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/
                if (this.check.checkHasEmpty(this.other)) {
                    this.$message.error("请填写完整信息")
                } else if (this.childClass.length == 0 || this.childName.length == 0) {
                    this.$message.error("请填写完整孩子的信息")
                } else if (!myreg.test(str)) {
                    this.$message.error("请填写正确手机号码")
                } else {
                    const res = await this.http.post("show/failCommit", {
                        main: arr,
                        other: this.other
                    })
                    if (res.code == 200) {
                        this.$message.success("提交成功")
                        this.status = 2
                    }
                    this.childId = res.data
                }
            }
        },
    }
</script>

<style scoped="true">
    .home {
        height: 100vh;
        width: 100vw;
        overflow-x: hidden;
        background-image: url(../assets/img/bg.png);
        position: fixed;
        margin-left: -0.5rem;
        margin-top: -0.5rem;
    }

    .contain {
        align-items: flex-end;
        text-align: left;
        justify-content: center;
        display: flex;
    }

    /deep/.el-descriptions-item__label.is-bordered-label {
    width: 100px !important;
}
.el-descriptions--mini.is-bordered td, .el-descriptions--mini.is-bordered th {
    padding: 6px 0px;
}
</style>
